import { polygon, sepolia } from "wagmi/chains";
import { injected, walletConnect } from "wagmi/connectors";
import { envConfig } from "../env-config";

const createConfig = require("wagmi").createConfig;
const http = require("wagmi").http;

const config = createConfig({
  chains: [polygon, sepolia],
  connectors: [
    injected(),
    walletConnect({
      projectId: envConfig.projectId,
    }),
  ],
  transports: {
    [polygon.id]: http(),
    [sepolia.id]: http(),
  },
});

export { config };
